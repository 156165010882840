.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.select-container {
  /* 地址选择弹层 */
  /* 修改的格式 */
}
.select-container .address {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 121;
  background: #fff;
  width: 100%;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.select-container .togeHeight {
  height: 7.7rem;
}
.select-container .layout {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 120;
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: #333;
  visibility: hidden;
}
.select-container .layoutBg {
  opacity: 0.7;
  visibility: visible;
}
.select-container .area {
  float: left;
  display: inline-block;
  font-size: 0.28rem;
  height: 0.82rem;
  line-height: 0.82rem;
  margin-left: 0.3rem;
  color: #333;
  max-width: calc(100% - 83%);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-container .addList {
  padding: 0 0.3rem;
  font-size: 0.3rem;
  line-height: 0.67rem;
  color: #262e31;
}
.select-container .address .address_slide_head {
  position: relative;
  overflow: hidden;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  background: #fff;
  font-size: 0.28rem;
  color: #333;
}
.select-container .address ul {
  height: calc(100% - 1.62rem);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.select-container .address ul .active {
  color: var(--main-color);
}
.select-container .address ul .active span::after {
  content: '';
  width: 0.4rem;
  height: 0.2rem;
  background-repeat: no-repeat;
  background-size: 0.2rem 0.13rem;
  background-position: left 0.16rem center;
  display: inline-block;
}
.select-container .address ul li {
  list-style: none;
}
.select-container .address ul .w32 {
  width: 0.32rem;
}
.select-container .address .title {
  height: 0.82rem;
}
.select-container .address .title .ac-color {
  color: var(--main-color);
  border-bottom: 0.04rem solid var(--main-color);
}
.select-container .address .determine {
  display: inline-block;
  width: 0.75rem;
  text-align: right;
  float: right;
  height: 0.82rem;
  line-height: 0.82rem;
  margin-right: 0.3rem;
  color: var(--main-color);
  font-size: 0.28rem;
}
